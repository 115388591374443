<template></template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import eventBus from "@/services/eventBus";
import apiClient from "@/utils/api";

export default defineComponent({
  name: "CallbackDiscord",
  setup() {
    const userInfo = ref<any>(null);
    const guildsInfo = ref<any>(null);
    const router = useRouter();

    const fetchUserInfo = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code) {
        try {
          const response = await apiClient.get("/callback", {
            params: { code },
          });

          userInfo.value = response.data.user;
          guildsInfo.value = response.data.guilds;

          localStorage.setItem("user", JSON.stringify(userInfo.value));
          localStorage.setItem("guilds", JSON.stringify(guildsInfo.value));
          eventBus.emit("auth-changed", true);

          router.push("/dashboard");
        } catch (error) {
          console.error("Erro ao obter informações do usuário:", error);
        }
      }
    };

    onMounted(fetchUserInfo);

    return { userInfo, guildsInfo };
  },
});
</script>
