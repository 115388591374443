<template>
  <div id="app">
    <Header @toggle-sidebar="toggleSidebar" />
    <Sidebar :isActive="isSidebarActive" />
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Header from "./components/common/NavBar.vue";
import Sidebar from "./components/common/SideBar.vue";

export default defineComponent({
  components: {
    Header,
    Sidebar,
  },
  setup() {
    const isSidebarActive = ref(false);

    function toggleSidebar() {
      isSidebarActive.value = !isSidebarActive.value;
    }

    return {
      isSidebarActive,
      toggleSidebar,
    };
  },
});
</script>
