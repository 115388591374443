import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1b1b93e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main_team" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SkeletonCard = _resolveComponent("SkeletonCard")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(4, (n) => {
          return _createVNode(_component_SkeletonCard, { key: n })
        }), 64))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.users, (user) => {
          return (_openBlock(), _createElementBlock("div", {
            key: user.id,
            class: "card"
          }, [
            _createVNode(_component_Card, {
              bannerUrl: user.bannerUrl,
              avatarUrl: `https://cdn.discordapp.com/avatars/${user.id}/${
            user.avatar
          }${user.avatar.startsWith('a_') ? '.gif' : '.png'}?size=2048`,
              role: user.role,
              name: user.username,
              id: user.id
            }, null, 8, ["bannerUrl", "avatarUrl", "role", "name", "id"])
          ]))
        }), 128))
  ]))
}