import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-30e798d1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "profile-image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "role" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "background-image",
      style: _normalizeStyle({ backgroundImage: `url(${_ctx.bannerUrl})` })
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.avatarUrl,
        alt: "Profile Image"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.role), 1),
    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.name), 1),
    _createElementVNode("div", _hoisted_6, "(" + _toDisplayString(_ctx.id) + ")", 1)
  ]))
}