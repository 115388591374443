<template>
  <MoneyRain />
  <section class="violet_apresentation">
    <img src="../../assets/image.png" alt="Violet Icon" class="violet_icon" />
    <h2>
      <span class="color_rose">Violet</span>, a melhor bot de simulação de vida
      do <span class="color_discord">Discord</span>.
    </h2>
    <p class="text-info">
      Violet, a bot que irá trazer um mundo repleto de tarefas e funcionalidades
      para o seu servidor do Discord, contento vários sistemas de economia para
      representar a vida real.
    </p>
    <section class="buttons">
      <button class="violet_add vibrading"><a href="https://discord.com/oauth2/authorize?client_id=1261110252087152710">Me adicione!</a></button>
      <button class="discord_button"><a href="https://discord.gg/VKQHuas29A">Meu servidor!</a></button>
    </section>
    <i
      class="fa-solid fa-angle-down float"
      style="font-size: 33px; margin-top: 10px; color: white"
    ></i>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MoneyRain from "../common/MoneyRain.vue";

export default defineComponent({
  components: {
    MoneyRain,
  },
});
</script>
