<template>
  <section class="violet_status">
    <section class="boxes">
      <h2>Estatísticas da Violet</h2>
      <section class="boxi">
        <div class="boxes-all">
          <canvas id="chart1" width="300" height="300"></canvas>
        </div>
      </section>
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
  setup() {
    onMounted(() => {
      const ctx1 = document.getElementById("chart1") as HTMLCanvasElement;
      new Chart(ctx1, {
        type: "line",
        data: {
          labels: ["Servidores", "Usuários"],
          datasets: [
            {
              label: "Servidores",
              data: [0, 10],
              fill: false,
              borderColor: "rgba(75, 192, 192, 1)",
              tension: 0.1,
            },
            {
              label: "Usuários",
              data: [0, 100],
              fill: false,
              borderColor: "rgba(153, 102, 255, 1)",
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top" as const,
            },
            tooltip: {
              callbacks: {
                label: function (tooltipItem) {
                  return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`;
                },
              },
            },
          },
        },
      });
    });
  },
});
</script>
