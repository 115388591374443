<template>
  <main>
    <VioletPresentation />
    <VioletStatus />
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import VioletPresentation from "../../components/presentation/VioletPresentation.vue";
import VioletStatus from "../../components/presentation/VioletStatus.vue";

export default defineComponent({
  components: {
    VioletPresentation,
    VioletStatus,
  },
});
</script>