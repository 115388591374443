<template>
  <div class="search-container">
    <input
      class="input-menu"
      type="text"
      id="search"
      placeholder="Pesquisar Comandos..."
    />
    <ul id="search-suggestions" class="search-suggestions"></ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  setup() {
    onMounted(() => {
      const searchInput = document.getElementById("search") as HTMLInputElement;
      const searchSuggestions = document.getElementById(
        "search-suggestions"
      ) as HTMLUListElement;

      searchInput.addEventListener("input", (e) => {
        const searchText = (e.target as HTMLInputElement).value.toLowerCase();
        searchSuggestions.innerHTML = "";

        if (searchText === "") {
          searchSuggestions.style.display = "none";
        } else {
          searchSuggestions.style.display = "block";
        }
      });
    });
  },
});
</script>
