<template>
  <div class="main_guilds">
    <h1>Servidores:</h1>
    <div v-if="loading">
      <SkeletonLoader />
    </div>
    <div v-else-if="guildsInfo && guildsInfo.length">
      <div class="cards">
        <div
          v-for="guild in guildsInfo"
          :key="guild.id"
          class="guild-card"
          @click="navigateToDaily(guild.id)"
        >
          <img
            :src="getGuildIconUrl(guild)"
            alt="Guild Icon"
            class="guild-icon"
          />
          <h2>{{ guild.name }}</h2>
          <p>(ID: {{ guild.id }})</p>
        </div>
      </div>
    </div>
    <p style="color: white" v-else>Nenhuma guilda encontrada...</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import SkeletonLoader from "@/components/ui/skeleton/SkeletonGuilds.vue";

export default defineComponent({
  name: "Dashboard",
  components: { SkeletonLoader },
  setup() {
    const guildsInfo = ref<any[]>([]);
    const loading = ref(true);
    const router = useRouter();

    onMounted(() => {
      setTimeout(() => {
        const guilds = localStorage.getItem("guilds");

        if (guilds) {
          guildsInfo.value = JSON.parse(guilds);
        }

        loading.value = false;
      }, 2000);
    });

    const getGuildIconUrl = (guild: any) => {
      if (guild.icon) {
        return `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`;
      } else {
        return "https://via.placeholder.com/128";
      }
    };

    const navigateToDaily = (guildId: string) => {
      router.push(`/dashboard/daily?guildId=${guildId}`);
    };

    return { guildsInfo, getGuildIconUrl, loading, navigateToDaily };
  },
});
</script>

<style scoped>
.main_guilds {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  background-color: #171717;
  margin: 20px;
  padding: 20px;
  border: 2px solid transparent;
  border-radius: 10px;
  min-height: 600px;
  color: white;
}

.main_guilds h1 {
  color: white;
  text-align: center !important;
  margin-bottom: 40px;
}

.cards {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.guild-card {
  width: 400px;
  background-color: #353535;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.guild-card p {
  margin-top: 5px;
  color: gray;
}

.guild-icon {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin-bottom: 15px;
  object-fit: cover;
}

@media (max-width: 600px) {
  .main_guilds {
    display: flex;
    align-items: center;
    background-color: transparent;
  }

  .cards {
    display: flex;
    flex-direction: column;
  }

  .guild-card {
    width: 300px;
    background-color: #353535;
    padding: 10px;
    border-radius: 10px;
  }
}
</style>
