import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/home/HomeView.vue";
import Commands from "../views/commands/CommandsView.vue";
import Team from "../views/team/TeamView.vue";
import RulesAndTerms from "@/views/privacy/RulesAndTermsView.vue";
import PrivacyPolicy from "@/views/privacy/PrivacyPolicyView.vue";
import Daily from "@/views/dashboard/DailyView.vue";
import CallbackDiscord from "@/views/discord/Callback.api.vue";
import Dashboard from "@/views/dashboard/DashboardView.vue";
import apiClient from "@/utils/api";

const routes: Array<RouteRecordRaw> = [
  { path: "/", component: Home },
  { path: "/commands", component: Commands },
  { path: "/team", component: Team },
  { path: "/terms", component: RulesAndTerms },
  { path: "/privacy", component: PrivacyPolicy },
  { path: "/dashboard/daily", component: Daily, meta: { requiresAuth: true } },
  { path: "/dashboard", component: Dashboard, meta: { requiresAuth: true } },
  { path: "/api/callback", component: CallbackDiscord },
];

const isAuthenticated = () => {
  return !!localStorage.getItem("user");
};

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated()) {
      window.location.href = apiClient.getUri() + "discord/login";
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
