<template>
  <div class="card skeleton-card">
    <div class="background-image"></div>
    <div class="profile-image"></div>
    <div class="role"></div>
    <div class="name"></div>
    <div class="id"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped>
.skeleton-card {
  margin-right: 40px;
  width: 250px;
  height: 300px;
  background: #4b4b4b;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  position: relative;
  font-family: Arial, sans-serif;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    background-color: #4b4b4b;
  }
  50% {
    background-color: #5c5c5c;
  }
  100% {
    background-color: #4b4b4b;
  }
}

.skeleton-card .background-image {
  width: 100%;
  height: 100px;
  background: #5c5c5c;
}

.skeleton-card .profile-image {
  width: 80px;
  height: 80px;
  background: #5c5c5c;
  border-radius: 50%;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.skeleton-card .role,
.skeleton-card .name,
.skeleton-card .id {
  margin-top: 40px;
  background: #5c5c5c;
  height: 20px;
  width: 80%;
  margin: 10px auto;
  border-radius: 4px;
}
</style>
