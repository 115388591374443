import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, { onToggleSidebar: _ctx.toggleSidebar }, null, 8, ["onToggleSidebar"]),
    _createVNode(_component_Sidebar, { isActive: _ctx.isSidebarActive }, null, 8, ["isActive"]),
    _createVNode(_component_router_view)
  ]))
}