<template>
  <div class="card">
    <div
      class="background-image"
      :style="{ backgroundImage: `url(${bannerUrl})` }"
    ></div>
    <div class="profile-image">
      <img :src="avatarUrl" alt="Profile Image" />
    </div>
    <div class="role">{{ role }}</div>
    <div class="name">{{ name }}</div>
    <div class="id">({{ id }})</div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    bannerUrl: {
      type: String as PropType<string | undefined>,
      required: true,
    },
    avatarUrl: {
      type: String as PropType<string>,
      required: true,
    },
    role: {
      type: String as PropType<string>,
      required: true,
    },
    name: {
      type: String as PropType<string>,
      required: true,
    },
    id: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
</script>

<style scoped>
.card {
  margin-right: 40px;
  width: 250px;
  background: #353535;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;
  position: relative;
  font-family: Arial, sans-serif;
}

.background-image {
  width: 100%;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-image {
  width: 80px;
  height: 80px;
  border: 3px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.role {
  margin-top: 40px;
  font-size: 14px;
  color: #c780e7;
  font-weight: bold;
}

.name {
  color: white;
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0;
}

.id {
  font-size: small;
  font-weight: bold;
  margin-bottom: 20px;
}
</style>
