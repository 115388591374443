<template>
  <div class="sidebar" :class="{ active: isActive }">
    <ul>
      <li>
        <router-link to="/commands"
          ><span class="color_premium"
            ><i class="fa-regular fa-gem"></i> Premium</span
          ></router-link
        >
      </li>
      <li><router-link to="/commands">Comandos</router-link></li>
      <li><router-link to="">Tutorial</router-link></li>
      <li><router-link to="/team">Equipe</router-link></li>
      <li><router-link to="/terms">Termos</router-link></li>
      <button class="discord_class-sidebar">
        <a href="https://discord.gg/VKQHuas29A"
          ><i class="fa-brands fa-discord"></i> Suporte</a
        >
      </button>
      <button class="discord_class-sidebar" @click="toggleAuth">
        <i
          :class="
            isLoggedIn ? 'fa-solid fa-door-closed' : 'fa-solid fa-door-open'
          "
        ></i>
        {{ isLoggedIn ? "Logout" : "Login" }}
      </button>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import eventBus from "@/services/eventBus";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { ref } from "vue";
import apiClient from "@/utils/api";

export default defineComponent({
  setup() {
    const router = useRouter();
    const isLoggedIn = ref(!!localStorage.getItem("user"));

    const toggleAuth = async () => {
      if (isLoggedIn.value) {
        localStorage.removeItem("user");
        isLoggedIn.value = false;
        eventBus.emit("auth-changed", false);
        router.push("/");
      } else {
        window.location.href = apiClient.getUri() + "discord/login";
      }
    };

    const updateAuthStatus = (status: boolean) => {
      isLoggedIn.value = status;
    };

    onMounted(() => {
      eventBus.on("auth-changed", updateAuthStatus);
    });

    return { isLoggedIn, toggleAuth };
  },
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
  },
});
</script>

<style scoped>
.sidebar {
  display: flex;
  justify-content: start;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background-color: #333;
  padding-top: 60px;
  transition: transform 0.3s ease-in-out;
  transform: translateX(-100%);
  z-index: 999;
}

.sidebar.active {
  transform: translateX(0);
}

.sidebar ul {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.sidebar li {
  padding: 5px;
  margin-top: 15px;
  margin-left: 10px;
  text-align: start;
  color: white;
  max-width: 130px;
  transition: 0.3s;
  cursor: pointer;
}

.sidebar ul li:hover {
  background-color: #262626;
  border-radius: 5px;
}
</style>
