<template>
  <div class="rules-container">
    <h1>Bem-vindo(a) ao servidor!</h1>
    <p>
      Para garantir um ambiente agradável e seguro para todos, por favor, leia e
      siga as regras abaixo. Se tiver sugestões ou críticas, utilize o canal de
      sugestões.
    </p>

    <h2>📕 Regras do Servidor:</h2>
    <ul>
      <li>
        <h3>1️⃣ Respeito Mútuo:</h3>
        <p>
          Seja educado e respeitoso com todos os membros. Ofensas, xingamentos,
          preconceito, racismo, homofobia, ou qualquer forma de discriminação
          não serão tolerados.
        </p>
      </li>
      <li>
        <h3>2️⃣ Spam e Flood:</h3>
        <p>
          Evite enviar mensagens em excesso ou sem sentido, pois isso atrapalha
          a comunicação. Mensagens em letras maiúsculas contínuas também são
          consideradas spam.
        </p>
      </li>
      <li>
        <h3>3️⃣ Conteúdo Inapropriado:</h3>
        <p>
          É proibido compartilhar conteúdo pornográfico, violento, ou qualquer
          material NSFW. Isso inclui textos, imagens, vídeos, e links.
        </p>
      </li>
      <li>
        <h3>4️⃣ Privacidade e Segurança:</h3>
        <p>
          Não compartilhe informações pessoais suas ou de outros membros sem
          permissão. Isso inclui nomes reais, endereços, números de telefone,
          etc.
        </p>
      </li>
      <li>
        <h3>5️⃣ Uso de Bots:</h3>
        <p>
          Utilize comandos de bots apenas nos canais apropriados. Abusar de bots
          ou usar contas alternativas para obter vantagens é proibido.
        </p>
      </li>
      <li>
        <h3>6️⃣ Assuntos Sensíveis:</h3>
        <p>
          Discussões sobre temas sensíveis como religião, política e sexualidade
          devem ser feitas com cuidado. Evite causar desconforto ou desordem.
        </p>
      </li>
      <li>
        <h3>7️⃣ Divulgação e Comércio:</h3>
        <p>
          É proibido divulgar outros servidores ou realizar qualquer tipo de
          comércio sem autorização prévia de um administrador.
        </p>
      </li>
      <li>
        <h3>8️⃣ Marcação de Membros:</h3>
        <p>
          Use as marcações de cargos e pessoas com responsabilidade. Marcar
          membros sem motivo válido é considerado perturbação.
        </p>
      </li>
      <li>
        <h3>9️⃣ Conduta Geral:</h3>
        <p>
          Não ameace, engane ou prejudique outros membros. Evitar punições
          utilizando contas alternativas resultará em banimento da conta
          principal e da alternativa.
        </p>
      </li>
      <li>
        <h3>🔟 Temática dos Canais:</h3>
        <p>
          Respeite a temática de cada canal. Mensagens fora de contexto devem
          ser evitadas para manter a organização.
        </p>
      </li>
    </ul>

    <h2>📱 Regras Específicas para o Bot:</h2>
    <ul>
      <li>
        <h3>1️⃣ Contas Alternativas:</h3>
        <p>
          O uso de contas alternativas para obter benefícios no bot é
          estritamente proibido.
        </p>
      </li>
      <li>
        <h3>2️⃣ Abuso de Bugs:</h3>
        <p>
          Se encontrar um bug, reporte imediatamente à equipe. Explorar falhas
          sem reportar é considerado abuso.
        </p>
      </li>
      <li>
        <h3>3️⃣ Comércio de Moedas Virtuais:</h3>
        <p>
          É proibido vender ou trocar moedas virtuais do bot por produtos ou
          serviços com valor monetário real.
        </p>
      </li>
      <li>
        <h3>4️⃣ Evasão de Punições:</h3>
        <p>
          Evitar ou burlar punições emitidas pela equipe utilizando o bot é uma
          infração grave.
        </p>
      </li>
      <li>
        <h3>5️⃣ Interações com Membros:</h3>
        <p>
          Não engane, burle ou ameace outros membros utilizando as
          funcionalidades do bot.
        </p>
      </li>
    </ul>

    <p>
      ⚠️ Importante: Siga também os Termos de Serviço do Discord para garantir
      uma experiência segura para todos.
    </p>
    <p>
      Os moderadores têm autoridade para aplicar penalidades conforme
      necessário. "Não sabia dessa regra" não será aceito como desculpa.
    </p>
    <p>Agradecemos pela compreensão e colaboração!</p>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style scoped>
.rules-container {
  padding: 20px;
  margin: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.rules-container h1,
.rules-container h2 {
  color: #333;
}

.rules-container ul {
  list-style: none;
  padding: 0;
}

.rules-container li {
  margin-bottom: 20px;
}

.rules-container h3 {
  margin: 10px 0;
}

.rules-container p {
  margin: 5px 0;
  color: #555;
}
</style>
