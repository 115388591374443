import apiClient from "../utils/api";

interface User {
  id: string;
  role: string;
  username: string;
  avatar: string;
  bannerUrl: string;
}

interface UserType {
  id: string;
  role: string;
  username: string;
  avatar: string;
  banner: string;
}

export const fetchUsers = async (): Promise<User[]> => {
  try {
    const response = await apiClient.get("/discord/data/get");
    const users = response.data.user;

    return users.map((user: UserType) => ({
      id: user.id,
      role: user.role,
      username: user.username,
      avatar: user.avatar,
      bannerUrl: user.banner != null
        ? `https://cdn.discordapp.com/banners/${user.id}/${user.banner}${
            user.banner?.startsWith("a_") ? ".gif" : ".png"
          }?size=2048`
        : "https://cdn.discordapp.com/attachments/858176561386618910/1258632644615208980/cad561f52f6d06be261189c774bf5475.jpg?ex=66a7bbbe&is=66a66a3e&hm=63596eec8d494661ec19ed8785344a4aaf75c3e00f0b1113791fffa19b2d857d&",
    }));
  } catch (error) {
    console.error("Erro ao buscar dados dos usuários:", error);
    throw error;
  }
};
