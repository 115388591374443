<template>
  <main class="main_team">
    <template v-if="loading">
      <SkeletonCard v-for="n in 4" :key="n" />
    </template>
    <template v-else>
      <div v-for="user in users" :key="user.id" class="card">
        <Card
          :bannerUrl="user.bannerUrl"
          :avatarUrl="`https://cdn.discordapp.com/avatars/${user.id}/${
            user.avatar
          }${user.avatar.startsWith('a_') ? '.gif' : '.png'}?size=2048`"
          :role="user.role"
          :name="user.username"
          :id="user.id"
        />
      </div>
    </template>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Card from "@/components/ui/Card.vue";
import SkeletonCard from "@/components/ui/skeleton/SkeletonCard.vue";
import { fetchUsers } from "@/services/userService";

interface User {
  id: string;
  role: string;
  username: string;
  avatar: string;
  bannerUrl?: string;
}

export default defineComponent({
  components: {
    Card,
    SkeletonCard,
  },
  data() {
    return {
      users: [] as User[],
      loading: true,
      error: null as string | null,
    };
  },
  async created() {
    try {
      const users = await fetchUsers();
      this.users = users;
    } catch (error) {
      this.error = "Failed to load users";
    } finally {
      this.loading = false;
    }
  },
});
</script>

<style scoped>
.main_team {
  display: flex;
  margin: 20px;
  padding: 40px;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
  background-color: #171717;
  border: 2px solid transparent;
  border-radius: 10px;
}
</style>
