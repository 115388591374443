<template>
  <div class="cards">
    <div v-for="n in 6" :key="n" class="guild-card skeleton">
      <div class="skeleton-icon"></div>
      <div class="skeleton-text line1"></div>
      <div class="skeleton-text line2"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SkeletonLoader",
});
</script>

<style scoped>
.cards {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
}

.skeleton {
  width: 400px;
  background-color: #353535;
  padding: 10px;
  border-radius: 10px;
  cursor: not-allowed;
}

.skeleton-icon,
.skeleton-text {
  background-color: #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
  animation: shimmer 1.5s infinite linear;
}

.skeleton-icon {
  width: 100px;
  height: 100px;
}

.skeleton-text {
  width: 60%;
  height: 20px;
}

.skeleton-text.line1 {
  width: 80%;
}

.skeleton-text.line2 {
  width: 40%;
}

@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

.skeleton-icon,
.skeleton-text {
  background: linear-gradient(90deg, #ccc, #e0e0e0, #ccc);
  background-size: 200% 100%;
}
</style>
