<template>
  <main class="main_commands">
    <section class="header">
      <h1><i class="fas fa-search search-icon"></i> Violet Comandos</h1>
    </section>

    <div class="container-main">
      <SearchBar />
      <nav class="nav-bar">
        <h2>Categorias</h2>
        <ul>
          <li class="nav-item active">
            <a href="#" data-category="all"
              ><i class="fas fa-globe"></i> Todos</a
            >
          </li>
          <li class="nav-item">
            <a href="#" data-category="vip" style="color: gold"
              ><i class="fas fa-star" style="color: gold"></i> Premium</a
            >
          </li>
          <li class="nav-item">
            <a href="#" data-category="private"
              ><i class="fas fa-lock"></i> Privado</a
            >
          </li>
          <li class="nav-item">
            <a href="#" data-category="economy"
              ><i class="fas fa-dice"></i> Economia</a
            >
          </li>
          <li class="nav-item">
            <a href="#" data-category="common"
              ><i class="fas fa-users"></i> Comum</a
            >
          </li>
        </ul>
      </nav>
      <div class="container-cmd">
        <section id="command-list"></section>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import SearchBar from "@/components/ui/SearchBar.vue";
import commands from "@/assets/json/commands.json";

export default defineComponent({
  components: {
    SearchBar,
  },
  setup() {
    onMounted(() => {
      const navItems = document.querySelectorAll(".nav-item");
      const commandList = document.getElementById(
        "command-list"
      ) as HTMLElement;

      function createCommandElement(command: {
        name: string;
        description: string;
        category: string;
      }) {
        const commandDiv = document.createElement("div");
        commandDiv.className = "command";
        commandDiv.dataset.category = command.category.toLowerCase();

        const nameElement = document.createElement("h2");
        nameElement.textContent = command.name;
        commandDiv.appendChild(nameElement);

        const descriptionElement = document.createElement("p");
        descriptionElement.textContent = command.description;
        commandDiv.appendChild(descriptionElement);

        return commandDiv;
      }

      function loadCommands() {
        commandList.innerHTML = "";
        commands.forEach(
          (command: {
            name: string;
            description: string;
            category: string;
          }) => {
            const commandElement = createCommandElement(command);
            commandList.appendChild(commandElement);
          }
        );
        updateCommands();
      }

      /* function loadCommands() {
        fetch("/assets/json/commands.json") 
          .then((response) => response.json())
          .then((commands) => {
            commandList.innerHTML = "";
            commands.forEach(
              (command: {
                name: string;
                description: string;
                category: string;
              }) => {
                const commandElement = createCommandElement(command);
                commandList.appendChild(commandElement);
              }
            );
            updateCommands();
          })
          .catch((error) => console.error("Erro ao carregar comandos:", error));
      } */

      function updateCommands() {
        const commands = document.querySelectorAll(".command");

        function showAllCommands() {
          commands.forEach((command) => {
            (command as HTMLElement).style.display = "block";
          });
        }

        function filterCommandsByCategory(category: string) {
          commands.forEach((command) => {
            const commandCategory = (command as HTMLElement).getAttribute(
              "data-category"
            );

            if (category === "all" || commandCategory === category) {
              (command as HTMLElement).style.display = "block";
            } else {
              (command as HTMLElement).style.display = "none";
            }
          });
        }

        function filterCommandsByText(text: string) {
          commands.forEach((command) => {
            const commandTitle =
              (command as HTMLElement)
                .querySelector("h2")
                ?.textContent?.toLowerCase() || "";

            if (commandTitle.includes(text) && text !== "") {
              (command as HTMLElement).style.display = "block";
            } else {
              (command as HTMLElement).style.display = "none";
            }
          });
        }

        navItems.forEach((item) => {
          item.addEventListener("click", (e) => {
            e.preventDefault();
            const category =
              (item as HTMLElement)
                .querySelector("a")
                ?.getAttribute("data-category") || "";
            filterCommandsByCategory(category);
          });
        });

        const searchInput = document.getElementById(
          "search"
        ) as HTMLInputElement;
        searchInput.addEventListener("input", (e) => {
          const searchText = (e.target as HTMLInputElement).value.toLowerCase();
          if (searchText === "") {
            showAllCommands();
          } else {
            filterCommandsByText(searchText);
          }
        });
      }

      loadCommands();
    });
  },
});
</script>

<style scoped>
@import url("@/assets/styles/commands.css");
</style>
