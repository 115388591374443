<template>
  <div class="page_daily">
    <div class="header">
      <h2>Recompensa Diária</h2>
      <p>
        O Bônus diário é a forma de você receber recompensas todos os dias, você
        pode receber entre <strong>1,000 à 5,000 Ruby</strong>. Com essas
        recompensas você pode apostar, comprar raspadinhas, comprar tickets para
        minha rifa, comprar lindos backgrounds para seu perfil e muito mais!
      </p>

      <h2>Aviso!</h2>
      <p>
        Não é permitido usar contas falsas para reivindicar a recompensa diária.
        Todas as recompensas devem ser reivindicadas honestamente. Aqueles que
        forem pegos reivindicando recompensas de forma fraudulenta terão sua e
        as demais contas punidas e não aceitaremos "não sabia desta regra" como
        justificativa.
      </p>
    </div>

    <div class="main_daily">
      <section class="daily-reward">
        <h2>Recompensa Diária</h2>
        <img src="../../assets/present.gif" alt="Presente" />
        <div v-if="rewardClaimed" class="claimed">
          <img src="../../assets/rubyImg.png" alt="Ruby icon" />
          <p>Parabéns, você acertou! Hoje você ganhou...</p>
          <p class="reward">
            <span class="color-red"
              >{{ rewardAmount.toLocaleString() }} Ruby!</span
            >
          </p>
        </div>
        <div v-else>
          <button @click="buttonReward" v-if="!isCooldownTime">
            Resgatar Prêmio
          </button>
          <p v-else>
            Você já resgatou sua recompensa diária hoje. Volte amanhã!
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script lang="ts">
import apiClient from "@/utils/api";
import { defineComponent, ref, onMounted } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "DailyReward",
  setup() {
    const userInfo = ref<any>(null);
    const route = useRoute();

    const rewardClaimed = ref(false);
    const isCooldownTime = ref(false);
    const rewardAmount = ref(0);

    onMounted(async () => {
      const user = localStorage.getItem("user");
      if (user) {
        userInfo.value = JSON.parse(user);
      }

      await checkCooldown();
    });

    const buttonReward = async () => {
      if (!userInfo.value || !route.query.guildId) {
        return alert("Erro: usuário ou ID do servidor não encontrado.");
      }

      try {
        const response = await apiClient.get(
          `/database/updateCoins?userId=${userInfo.value.id}&serverId=${route.query.guildId}`
        );
        rewardAmount.value = response.data.rewardAmount;
        rewardClaimed.value = true;
      } catch (error) {
        console.error("Error updating user data:", error);
        alert("Erro ao atualizar os dados do usuário.");
      }
    };

    const checkCooldown = async () => {
      if (!userInfo.value || !route.query.guildId) {
        return alert("Erro: usuário ou ID do servidor não encontrado.");
      }

      try {
        const response = await apiClient.get(
          `/database/getCooldown?userId=${userInfo.value.id}&serverId=${route.query.guildId}`
        );

        isCooldownTime.value = response.data.cd;
      } catch (error) {
        console.error("Error fetching cooldown data:", error);
        alert("Erro ao verificar o cooldown.");
      }
    };

    return {
      userInfo,
      rewardClaimed,
      rewardAmount,
      buttonReward,
      isCooldownTime,
    };
  },
});
</script>

<style scoped>
.page_daily {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: #333;
  margin: 20px;
  padding: 20px;
  border-radius: 10px;
}

.header h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.header p {
  width: 700px;
}

.main_daily {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.daily-reward {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.daily-reward button {
  padding: 10px;
  border: 2px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s;
}

.daily-reward button:hover {
  background-color: green;
}

.main_daily img {
  width: 300px;
  height: 300px;
}

.claimed {
  display: flex;
  align-items: center;
}

.claimed img {
  height: 150px;
  width: 150px;
  margin-right: 20px;
}

.color-red {
  color: red;
  font-weight: bold;
}
</style>
