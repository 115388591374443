import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VioletPresentation = _resolveComponent("VioletPresentation")!
  const _component_VioletStatus = _resolveComponent("VioletStatus")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createVNode(_component_VioletPresentation),
    _createVNode(_component_VioletStatus)
  ]))
}