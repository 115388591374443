import { reactive } from "vue";
type EventType = "auth-changed";

interface EventBus {
  emit(event: EventType, data?: any): void;
  on(event: EventType, callback: (data?: any) => void): void;
}

const eventBus = reactive({
  eventListeners: new Map<EventType, Array<(data?: any) => void>>(),

  emit(event: EventType, data?: any) {
    const listeners = this.eventListeners.get(event);
    if (listeners) {
      listeners.forEach((callback) => callback(data));
    }
  },

  on(event: EventType, callback: (data?: any) => void) {
    if (!this.eventListeners.has(event)) {
      this.eventListeners.set(event, []);
    }
    this.eventListeners.get(event)?.push(callback);
  },
}) as EventBus;

export default eventBus;
