<template>
  <div id="money-rain"></div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  setup() {
    onMounted(() => {
      const moneyRain = document.getElementById("money-rain")!;
      const maxMoney = 150;
      const emojiFrequency = 400;
      const removeDelay = 5000;

      function createMoney() {
        if (moneyRain.children.length >= maxMoney) return;

        const money = document.createElement("div");
        money.className = "money";
        money.textContent = "💸";
        money.style.left = `${Math.random() * 100}vw`;
        money.style.animationDuration = `${Math.random() * 5 + 3}s`;
        money.style.opacity = `${Math.random() * 0.7 + 0.3}`;
        money.style.transform = `rotate(${Math.random() * 360}deg)`;
        money.style.fontSize = `${Math.random() * 20 + 20}px`;

        moneyRain.appendChild(money);

        setTimeout(() => {
          if (money.parentNode) money.remove();
        }, removeDelay);
      }

      setInterval(createMoney, emojiFrequency);
    });
  },
});
</script>
